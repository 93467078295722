<template>
    <div>
        <el-form label-width="150px" :model="form" :rules="rules" ref="ruleForm">
            <el-form-item v-if="types === 'add' || types === 'edit'" label="役割名称" prop="roleName">
                <el-input v-model="form.roleName" type="text" :show-word-limit="true" maxlength="10"></el-input>
            </el-form-item>
            <el-form-item v-if="types === 'add' || types === 'setToo'" label="ナビゲーション権限" style="text-align: left"
                          prop="menuIds">
                <el-checkbox-group v-model="checkList" @change="changeCheck">
                    <template v-for="item in checkData">
                        <el-checkbox :label="item"></el-checkbox>
                    </template>
                </el-checkbox-group>
                <div class="tree-box">
                    <el-tree ref="menu" :data="treeData" show-checkbox
                             node-key="id" highlight-current @check="treeChange"
                             :props="defaultProps">
                    </el-tree>
                </div>
            </el-form-item>
            <el-form-item v-if="types === 'add' || types === 'edit'" label="備考" style="margin-top: 15px">
                <el-input v-model="form.remark" type="textarea" :show-word-limit="true" maxlength="500"
                          rows="4"></el-input>
            </el-form-item>
            <el-row style="margin-top: 20px;">
                <el-button @click="canl()" class="btn-width-big" icon="el-icon-back">キャンセル</el-button>
                <el-button type="success" class="btn-width-big" @click="save()" icon="el-icon-check">保存</el-button>
            </el-row>
        </el-form>
    </div>
</template>

<script>
    export default {
        name: "",
        props: ['types', 'data'],
        watch: {
        },
        data() {
            return {
                valiedck: false,
                expandAll: false,
                checkListOld: [],
                checkList: [],
                checkData: ['開く/折り畳', '全て選択'],
                form: {
                    roleId: '',
                    roleName: '',
                    radio: '1',
                    remark: '',
                    roleKey: 'nor',
                    menuIds: 'def'
                },
                rules: {
                    roleName: [
                        {required: true, message: "役割名称を入力", trigger: ["blur", "change"]}
                    ],
                    menuIds: [
                        {required: true, message: "ナビゲーション権限を入力", trigger: ["blur", "change"]}
                    ]
                },
                treeData: [],
                treeDataCopy: [],
                defaultProps: {
                    children: 'children',
                    label: 'label'
                }
            }
        },
        created() {
            if (this.types === 'edit' || this.types == 'setToo') {
                this.form.roleId = this.data.roleId;
                this.form.roleName = this.data.roleName;
                this.form.remark = this.data.remark;
                if (this.types === 'edit') {
                    this.form.menuIds = this.data.menuIds;
                }
                // this.form.menuIds = this.data.menuIds;
            }
            this.getTreeSelect();
        },
        mounted() {

        },
        methods: {
            setFormSelMenuIds(){
                if (this.types === 'setToo' && this.data && this.data.menuIds) {
                    this.form.menuIds = [];
                    let tp0 = this.treeDataCopy.find(item => { return item.id === 2000});
                    tp0.children.forEach(item => {
                        if (item.children && item.children[0] && item.children[0].id) {
                            let key_tp = item.children[0].id;
                            if (this.data.menuIds.includes(key_tp)) {
                                this.form.menuIds.push(item.id);
                            }
                        }
                    })
                    let tp1 = this.treeDataCopy.find(item => { return item.id === 2001});
                    let key1 = tp1.children[0].children[0].id;
                    if (this.data.menuIds.includes(key1)) {
                        this.form.menuIds.push(2001)
                    }
                    let tp2 = this.treeDataCopy.find(item => { return item.id === 2002});
                    let key2 = tp2.children[0].children[0].id;
                    if (this.data.menuIds.includes(key2)) {
                        this.form.menuIds.push(2002)
                    }
                }

            },
            // 全てのメニューノートデータ
            getMenuAllCheckedKeys() {
                // 現在選択されているメニューノート
                let checkedKeys = this.$refs.menu.getCheckedKeys();
                // 半選択メニューノート
                let halfCheckedKeys = this.$refs.menu.getHalfCheckedKeys();
                checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
                let keys = []
                let tp0 = this.treeDataCopy.find(item => { return item.id === 2000});
                tp0.children.forEach(item => {
                    if (checkedKeys.includes(item.id)) {
                        keys = keys.concat(this.getAllNodes(item.children));
                    }
                })
                if (checkedKeys.includes(2001)) {
                    let tp = this.treeDataCopy.find(item => { return item.id === 2001});
                    tp.children.forEach(item => {
                        keys = keys.concat(this.getAllNodes(item.children));
                        keys.push(item.id)
                    })
                }
                if (checkedKeys.includes(2002)) {
                    let tp = this.treeDataCopy.find(item => { return item.id === 2002});
                    tp.children.forEach(item => {
                        keys = keys.concat(this.getAllNodes(item.children));
                        keys.push(item.id)
                    })
                }
                return Array.from(new Set(checkedKeys.concat(keys)));
            },
            canl() {
                this.$emit('close')
            },
            async save() {
                this.form.roleKey += '_'+this.form.roleName;
                if (this.types !== 'edit') {
                    this.form.menuIds = this.getMenuAllCheckedKeys();
                }
                if (this.types === 'edit' || this.types === 'setToo') {
                    this.$refs.ruleForm.validate(async (valid) => {
                        if (!valid) {
                            this.valiedck = true;
                            return;
                        }
                        let res = await this.$http.sys.roleUpdate(this.form);
                        if (res.code === 200) {
                            this.$emit('close')
                            this.$emit('refresh')
                            this.$message.success(res.msg)
                        }
                    })
                } else {
                    this.$refs.ruleForm.validate(async (valid) => {
                        if (!valid) {
                            this.valiedck = true;
                            return;
                        }
                        let res = await this.$http.sys.roleAdd(this.form);
                        if (res.code === 200) {
                            this.$emit('close')
                            this.$emit('refresh')
                            this.$message.success(res.msg)
                        }

                    })
                }
            },
            async getTreeSelect() {
                let res = await this.$http.sys.treeSelect();
                if (res.code === 200) {
                    let tree = []
                    res.data.forEach(item => {
                        if (item.id === 2000) {
                            let tmp = {
                                children: [],
                                id: item.id,
                                label: item.label
                            };
                            item.children.forEach(subItem => {
                                tmp.children.push({
                                    id: subItem.id,
                                    label: subItem.label
                                })
                            })
                            tree.push(tmp)
                        } else {
                            tree.push({
                                id: item.id,
                                label: item.label
                            })
                        }
                    })
                    // this.treeData = res.data;
                    this.treeDataCopy = res.data;
                    this.setFormSelMenuIds()
                    if (this.types === 'setToo') {
                        this.$refs.menu.setCheckedKeys(this.form.menuIds)
                    }
                    this.treeData = tree;
                    let arr = this.getAllNodes(this.treeDataCopy);
                    if (arr && arr.length === this.form.menuIds.length) {
                        this.checkListOld= [this.checkData[1]],
                        this.checkList= [this.checkData[1]]
                    } else {
                        this.checkListOld= [],
                        this.checkList= []
                    }
                }
            },
            /**
             * 获取所有子节点
             * @param node
             * @param arr
             * @return {*[]}
             */
            getAllNodes(node = [],arr=[]) {
                for(let item of node) {
                    arr.push(item.id)
                    let parentArr = []
                    if(item.children) parentArr.push(...item.children)
                    if(parentArr && parentArr.length) this.getAllNodes(parentArr,arr)
                }
                return arr
            },
            treeChange(){
                setTimeout(() => {
                    this.form.menuIds = this.getMenuAllCheckedKeys();
                    let arr = this.getAllNodes(this.treeDataCopy);
                    let index = this.checkListOld.findIndex(item => {return item === this.checkData[1]})
                    if (arr && arr.length === this.form.menuIds.length && index < 0) {
                        this.checkListOld.push(this.checkData[1]),
                        this.checkList.push(this.checkData[1])
                    } else if (index > -1) {
                        this.checkList.splice(index,1)
                        this.checkListOld.splice(index,1)
                    }
                    if (this.valiedck || this.form.menuIds.length) {
                        this.$refs.ruleForm.validateField('menuIds', (valid) => {

                        })
                    }
                })
            },
            changeCheck(sels) {

                if (sels.indexOf(this.checkData[0]) > -1) {
                    this.expandAll = true;
                    this.expandNodes(this.$refs.menu.store.root)
                } else if(this.checkListOld.indexOf(this.checkData[0]) > -1) {
                    this.expandAll = false;
                    this.expandNodes(this.$refs.menu.store.root)
                }
                let flag = false;
                if (sels.indexOf(this.checkData[1]) > -1) {
                    flag = true;
                    this.checkAllIs(true)
                } else if (this.checkListOld.indexOf(this.checkData[1]) > -1) {
                    flag = true;
                    this.checkAllIs(false)
                }
                setTimeout(() => {
                    this.checkListOld = sels;
                    if (flag && this.valiedck) {
                        this.form.menuIds = this.getMenuAllCheckedKeys();
                        this.$refs.ruleForm.validateField('menuIds', (valid) => {

                        })
                    }
                })
            },
            checkAllIs(flag) {
                if (flag) {
                    this.$nextTick(() => {
                        this.$refs.menu.setCheckedNodes(this.treeDataCopy);
                    })
                } else {
                    this.$nextTick(() => {
                        this.$refs.menu.setCheckedKeys([])
                    })
                }
            },
            // ツリーデータを遍歴し，各項目のexpanded属性を設定する，展開を実現する
            expandNodes(node) {
                node.expanded = this.expandAll;
                for (let i = 0; i < node.childNodes.length; i++) {
                    node.childNodes[i].expanded = this.expandAll;
                    if (node.childNodes[i].childNodes.length > 0) {
                        this.expandNodes(node.childNodes[i]);
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
  /deep/ .el-radio__inner {
    border-radius: 0px;
  }

  .tree-box {
    width: 100%;
    height: 210px;
    border: 1px solid rgba(170, 170, 170, 35%);
    overflow: hidden;
    overflow-y: scroll;
  }
</style>
