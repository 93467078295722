<template>
    <div>
        <sub-header :titles="subTitles" @refresh="refresh"></sub-header>
        <div class="tab-box">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane v-if="jurAthUser()" label="ユーザー管理" name="first">
                    <div class="sub-search">
                        <el-form ref="form" :model="form" :inline="true" style="text-align: left;">
                            <el-form-item class="input-item">
                                <el-input v-model="form.nickName" style="width: 255px" placeholder="ユーザー名を検索する"
                                          suffix-icon="el-icon-search"
                                ></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-select v-model="form.status" placeholder="全ての状態" style="width: 140px">
                                    <el-option v-for="item in options"
                                               :key="item.value"
                                               :label="item.label"
                                               :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item class="date-item">
                                <el-date-picker
                                        v-model="createDate"
                                        type="daterange"
                                        align="center"
                                        style="width: 270px"
                                        range-separator="-"
                                        start-placeholder="開始日付"
                                        end-placeholder="終了日付"
                                        format="yyyy-MM-dd"
                                        value-format="yyyy-MM-dd"
                                >
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" icon="el-icon-search" @click="getUserList">検索</el-button>
                            </el-form-item>
                            <el-form-item>
                                <el-button icon="el-icon-refresh-right" @click="reForm">クリア</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </el-tab-pane>
                <el-tab-pane v-if="jurAthRole()" label="役割管理" name="second">
                    <role-manager ref="roleManager" v-if="tabIndex === '1'"></role-manager>
                </el-tab-pane>
                <el-tab-pane v-if="jurAthTask()" label="定例タスク" name="third">
                    <scheduled ref="scheduled" v-if="tabIndex === '2'"></scheduled>
                </el-tab-pane>
            </el-tabs>
        </div>
        <div v-show="activeName === 'first'" class="content">
            <el-row v-has-permi="['system:user:add']" style="text-align: left;margin-bottom: 10px">
                <el-button class="text-btn3" type="success" icon="el-icon-plus" @click="add">追加</el-button>
            </el-row>
            <el-table v-has-permi="['system:user:query']" :data="tableData" style="width: 100%"
                      :max-height="tableHeight">
                <el-table-column align="center" prop="userName" label="アカウント" width="90">
                </el-table-column>
                <el-table-column align="center" prop="nickName" label="ユーザー名" width="90">
                </el-table-column>
                <el-table-column align="center" prop="roleName" label="役割" width="100" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{getRoleName(scope.row)}}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="createTime" label="作成時間" width="160">
                </el-table-column>
                <el-table-column align="center" label="状態" width="60">
                    <template slot-scope="scope">
                        <el-switch style="display: block" v-model="scope.row.status" active-color="#13ce66"
                                   @change="userChangeStatus(scope.row)"
                                   inactive-color="rgba(242, 242, 242, 1)">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="remark" label="備考" width="150">
                    <template slot-scope="scope">
                        <cus-tooltip :val="scope.row.remark"></cus-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <div class="cz-box" style="display: flex;justify-content: center">
                            <el-button v-has-permi="['system:user:edit']" class="small-btn" type="primary" size="small"
                                       @click="editInfo(scope.row)">編集
                            </el-button>
                            <el-button v-has-permi="['system:user:resetPwd']" class="small-btn" type="primary" size="small"
                                       @click="setToo(scope.row)">パスワードを再設定
                            </el-button>
                            <el-button v-has-permi="['system:user:remove']" class="small-btn bg-color-red" type="danger"
                                       size="small" @click="deleteInfo(scope.row)">削除
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="pagina-box" background layout="prev, pager, next" :total="total"
                           :current-page="pageNo"
                           :page-sizes="pagesizes"
                           :page-size="pagesize"
                           @current-change="onPage"
                           @size-change="onSizeChange">
            </el-pagination>
        </div>
        <el-dialog :title="title" :visible.sync="dialogVisible" :width="'500px'"
                   :close-on-click-modal="false" :show-close="false">
            <user-add v-if="dialogVisible" @callBack="dialogVisible = false" @success="getUserList" :data="editRow"
                      :types="types"></user-add>
        </el-dialog>
    </div>
</template>

<script>
    import subHeader from '../../components/SubHeader'
    import userAdd from "../../pages/components/user-add";
    import roleManager from "../../pages/components/role-manager";
    import scheduled from "../../pages/components/scheduled";
    import CusTooltip from "../../components/CusTooltip";
    import store from "../../../store";
    export default {
        name: "system",
        components: {userAdd, subHeader, roleManager, scheduled,CusTooltip},
        data() {
            return {
                title: '',
                pageNo: 1,
                pagesizes: [10, 20, 30, 50, 100, 200, 500],
                pagesize: 10,
                total: 0,
                editRow: {},
                tabTile: ['ユーザー管理', '役割管理', '定例タスク'],
                tabIndex: '0',
                types: '',
                tableHeight: 0,
                tableHeight1: 0,
                dialogVisible: false,
                subTitles: '',
                activeName: 'first',
                
                //日期范围
                createDate: [],
                form: {
                    nickName: '',
                    status: '',
                    params: {
                        beginTime: '',
                        endTime: '',
                        pageNum: '',
                        pageSize: ''
                    }
                },
                options: [
                    {
                        label: '全ての状態',
                        value: ''
                    }, {
                        label: '正常',
                        value: '0'
                    }, {
                        label: '禁止',
                        value: '1'
                    },
                ],
                tableData: [],
            }
        },
        mounted() {
            this.subTitles = '/ユーザー管理';
            //获取容器当前高度，重设表格的最大高度
            this.getTableMaxHeight();
            let _this = this;
            window.onresize = function () {//用于使表格高度自适应的方法
                _this.getTableMaxHeight();//获取容器当前高度，重设表格的最大高度
            }

             this.getUserList();
        },
        methods: {
            jurAthUser(){
                const permissions = store.getters && store.getters.permissions;
                return permissions.includes('system:user:list') || permissions.includes('*:*:*')
            },
            jurAthRole(){
                const permissions = store.getters && store.getters.permissions;
                return permissions.includes('system:role:list') || permissions.includes('*:*:*')
            },
            jurAthTask(){
                const permissions = store.getters && store.getters.permissions;
                return permissions.includes('system:task:list') || permissions.includes('*:*:*')
            },
            /**
             * 获取角色名称
             **/
            getRoleName(row){
                if (row.roles && row.roles.length) {
                    return row.roles[0].roleName;
                }
                return '';
            },
            /**
             * 刷新
             **/
            refresh() {
                if (this.tabIndex === '0') {
                    this.pageNo = 1;
                    this.getUserList();
                } else if (this.tabIndex === '1') {
                    this.$refs.roleManager.pageNo = 1;
                    this.$refs.roleManager.getRoleList();
                } else if (this.tabIndex === '2') {
                    this.$refs.scheduled.pageNo = 1;
                    this.$refs.scheduled.getList();
                }
            },
            onPage(num) {
                this.pageNo = num;
                this.getUserList()
            },
            // 每页显示数量改变事件
            onSizeChange(size) {
                this.pageNo = 1;
                this.pagesize = size;
                this.getUserList()
            },
            async userChangeStatus(row) {
                let res = await this.$http.sys.userChangeStatus({
                    userId: row.userId,
                    status: row.status ? '0' : '1'
                })
                if (res.code == 200) {
                    this.$message.success(res.msg)
                } else {
                    row.status = !row.status
                }
            },

            reForm() {
                this.createDate = "",
                    this.form = {
                        nickName: '',
                        status: '',
                        params: {
                            beginTime: '',
                            endTime: ''
                        }
                    }
            },
            async getUserList() {
                this.dialogVisible = false;
                if (this.createDate) {
                    this.form.params.beginTime = this.createDate[0]
                    this.form.params.endTime = this.createDate[1]
                } else {
                    this.form.params.beginTime = ''
                    this.form.params.endTime = ''
                }
                this.form.pageNum = this.pageNo;
                this.form.pageSize = this.pagesize;
                let res = await this.$http.sys.userList(this.form);
                this.tableData = [];
                if (res.code === 200) {
                    this.total = res.total;
                    res.rows.forEach(item => {
                        item.status = item.status === '0'
                        if(item.roles != undefined  && item.roles.length>0 ){
                          item.roleId = item.roles[0].roleId
                        }
                        this.tableData.push(item);
                    })
                }
            },
            add() {
                this.title = 'アカウントを追加'
                this.types = 'add';
                this.dialogVisible = true;
            },
            handleClick(tab, event) {
                this.tabIndex = tab.index;
                this.subTitles = '/' + this.tabTile[this.tabIndex];
            },
            editInfo(row) {
                this.title = 'アカウントを編集'
                this.types = 'edit';
                this.editRow = row;
                this.dialogVisible = true;
            },
            async setToo(row) {
                this.title = 'パスワードを再設定'
                this.editRow = row
                this.types = 'setToo';
                this.dialogVisible = true;
            },
            deleteInfo(row) {
                let _this = this;
                this.$confirm('削除は確定ですか ?', '提示', {
                    confirmButtonText: '確定',
                    cancelButtonText: 'キャンセル',
                    type: 'warning'
                })
                    .then(() => {
                        _this.delUser(row)
                    })
                    .catch(() => {
                    })
            },
            async delUser(user) {
                let res = await this.$http.sys.delUser({userIds: [user.userId]});
                if (res.code == 200) {
                    this.$message.success(res.msg)
                    this.getUserList();
                }
            },
            //获取容器当前高度，重设表格的最大高度
            getTableMaxHeight() {
                this.$nextTick(function () {
                    if (document.querySelector(".content")) {
                        let box = document.querySelector(".content").attributes
                        let box_clientHeight = box[0].ownerElement.clientHeight;
                        this.tableHeight = box_clientHeight - 160;
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
  .tab-box {
    box-shadow: 0px 0px 15px rgba(170, 170, 170, 35%);
    padding: 20px 30px;
    background: #FFF;

    .sub-search {
      .date-item {
        /deep/ .el-input__prefix {
          left: 1px;
          -webkit-transition: all .3s;
          transition: all .3s;
          width: 40px;
          background: rgba(242, 242, 242, 1);
          height: 38px;
          top: 1px;
        }

        /deep/ .el-input__inner {
          padding-left: 45px;
        }
      }
    }
  }

  .content {
    margin-top: 20px;
    box-shadow: 0px 0px 15px rgba(170, 170, 170, 35%);
    padding: 20px 30px;
    background: #FFF;
    height: calc(100vh - 440px);
    /deep/ .el-button {
      font-weight: 400;
      padding: 12px 12px;
      border-radius: 0px;
      border: 1px solid #f2f2f2;
      align-items: center;
      display: flex;
    }

    .pagina-box {
      margin-top: 20px;
      text-align: right;
    }
  }

  .cz-box {
    /deep/ .el-button {
      font-family: 'PingFangSC-Regular', 'PingFang SC', sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 10px;
      color: #FFFFFF;
    }
  }

  /deep/ .el-table .cell, .el-table th div {
    padding-left: 0px;
    padding-right: 0px;
  }
  .small-btn {
    color: white;
    height: 24px !important;
    padding: 9px 15px !important;
    line-height: 1px;
  }
</style>
