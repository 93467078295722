<template>
  <div class="sub-header">
    <span>
      <template >
        {{title}}
      </template>
    </span>
    <span @click="refresh"> <i class="el-icon-refresh"></i> 更新</span>
  </div>
</template>

<script>
  export default {
    name: "subHeader",
    props: {
      titles: {
        type: String,
        default: ''
      }
    },
    watch: {
      titles(val) {
        if (val && val.indexOf('/') > -1) {
          this.title = localStorage.getItem('subTitle') + val;
        } else if(val) {
          this.title = val;
        } else {
          this.title = localStorage.getItem('subTitle');
        }
      }
    },
    data(){
      return {
        title: ''
      }
    },
    mounted() {
      this.title = localStorage.getItem('subTitle');
    },
    methods: {
      refresh(){
        this.$emit('refresh')
      },
      setUser(){
        this.title = 'ユーザーを設定';
      },
      logoOut(){
        this.$confirm('ログアウトは確定ですか ?', '提示', {
          confirmButtonText: '確定',
          cancelButtonText: 'キャンセル',
          type: 'warning'
        })
          .then(() => {
            this.$router.push('/');
          })
          .catch(() => {})
      }
    }
  }
</script>

<style lang="scss" scoped>
  .sub-header {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      display: inline-block;
      font-family: 'PingFangSC-Regular', 'PingFang SC', sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      color: #AAAAAA;
    }
    span:nth-child(2) {
      cursor: pointer;
    }
  }
</style>
