<template>
  <div>
    <el-form ref="form" label-width="140px" :model="form":rules="rules">
      <el-form-item v-if="types === 'add'" label="アカウント" prop="userName">
        <el-input v-model="form.userName" type="text" :show-word-limit="true" placeholder="ログインアカウントを入力"
                  maxlength="20"></el-input>
      </el-form-item>
      <el-form-item v-if="types === 'add'" label="ユーザー名" prop="nickName">
        <el-input v-model="form.nickName" type="text" :show-word-limit="true" placeholder="ユーザーの名前を入力"
                  maxlength="10"></el-input>
      </el-form-item>
      <el-form-item v-if="types === 'add' || types === 'edit'" label="役割" prop="roleId">
        <el-select v-model="form.roleId" placeholder="アカウントキャラクターを選択" style="width: 100%">
          <template v-for="item in roleList">
            <el-option :label="item.roleName" :value="item.roleId" :key="item.roleId"></el-option>
          </template>
        </el-select>
      </el-form-item>
      <el-form-item v-if="types === 'add' || types === 'setToo'" label="パスワード" prop="password">
        <el-input v-model="form.password" type="passWord" :show-word-limit="true" placeholder="ログインパスワードを入力"
                  maxlength="20"></el-input>
      </el-form-item>
      <el-form-item v-if="types === 'add' || types === 'setToo'" label="パスワードを確認" prop="password2">
        <el-input v-model="form.password2" type="passWord" :show-word-limit="true" placeholder="もう一度パスワードを入力"
                  maxlength="20"></el-input>
      </el-form-item>
      <el-form-item v-if="types === 'add' || types === 'edit'" label="備考">
        <el-input v-model="form.remark" type="textarea" :show-word-limit="true" maxlength="500" rows="4"></el-input>
      </el-form-item>
      <el-row style="text-align: center;">
          <el-button class="btn-width-big" @click="back" icon="el-icon-back">キャンセル</el-button>
          <el-button class="btn-width-big" type="success" @click="save" icon="el-icon-check">保存</el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
  export default {
    name: "",
    props: ['types','data'],
    data(){
      return {
        roleList:[],
        form: {
          userId: '',
          userName:'',
          nickName: '',
          roleId: '',
          password: '',
          password2: '',
          remark: ''
        },
        rules:{
          userName: [
            { required: true, message: 'ログインアカウントを入力', trigger: 'blur' },
          ],
          nickName: [
            { required: true, message: 'ユーザーの名前を入力', trigger: 'blur' },
          ],
          roleId: [
            { required: true, message: 'ログインパスワードを入力', trigger: 'blur' },
          ],
          password: [
            { required: true, message: 'アカウントキャラクターを選択', trigger: 'blur' },
          ],
          password2: [
            { required: true, message: 'もう一度パスワードを入力', trigger: 'blur' },
          ]
        }
      }
    },
    mounted() {
      if (this.types === 'edit' || this.types === 'setToo') {
        this.form.roleId = this.data.roleId;
        this.form.userId = this.data.userId;
        this.form.userName = this.data.userName
        this.form.remark = this.data.remark;
      }
      this.getRoleList();
    },
    methods: {
      async getRoleList(){
        let res = await this.$http.sys.roleOptionSelect();
        if (res.code === 200) {
          this.roleList = res.data
        }
      },
      back(){
        this.$emit('callBack');
      },
      save(){
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            this.form.roleIds = [this.form.roleId]
            if ((this.types === 'add' || this.types === 'setToo') && this.form.password !== this.form.password2) {
              this.$message.warning("パスワードが2回一致しない")
            } else {
              let res = null;
              if (!this.form.userId) {
                res = await this.$http.sys.userAdd(this.form);
              } else {
                if (this.types === 'edit') {
                  res = await this.$http.sys.userUpdate(this.form);
                }else {
                  res = await this.$http.sys.resetPwd(this.form)
                }
              }
              if (res.code == 200) {
                this.$message.success(res.msg)
                this.$emit('success',this.form);
              } else {
                row.status = !row.status
              }
            }
          }
        });
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
