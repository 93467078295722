<template>
    <div class="content2">
        <el-row v-has-permi="['system:role:add']" class="sub-box">
            <el-button type="success" class="text-btn3" icon="el-icon-plus" @click="add">追加</el-button>
        </el-row>
        <template v-has-permi="['system:role:query', 'system:role:list']">
            <el-table :data="tableData" style="width: 100%" :max-height="tableHeight">
                <el-table-column align="center" prop="roleName" label="役割名称" width="180">
                </el-table-column>
                <el-table-column align="center" prop="createTime" label="作成時間" width="180">
                </el-table-column>
                <el-table-column align="center" label="状態" width="80">
                    <template slot-scope="scope">
                        <el-switch style="display: block" v-model="scope.row.status"
                                   @change="statusChange(scope.row)"
                                   active-color="#13ce66"
                                   inactive-color="rgba(242, 242, 242, 1)">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="remark" label="備考" width="220">
                    <template slot-scope="scope">
                        <cus-tooltip :val="scope.row.remark"></cus-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <div class="cz-box" style="display: flex;justify-content: center">
                            <el-button v-has-permi="['system:role:edit']" class="small-btn" type="primary" size="small"
                                       @click="editInfo(scope.row)">編集
                            </el-button>
                            <el-button v-has-permi="['system:role:menuAccess']" class="small-btn" type="primary" size="small"
                                       @click="setToo(scope.row)">データ許可
                            </el-button>
                            <el-button v-has-permi="['system:role:remove']" class="small-btn bg-color-red" type="denger"
                                       size="small" @click="deleteInfo(scope.row)">削除
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="pagina-box" background layout="prev, pager, next" :total="total"
                           :current-page="pageNo"
                           :page-sizes="pagesizes"
                           :page-size="pagesize"
                           @current-change="onPage"
                           @size-change="onSizeChange">
            </el-pagination>
        </template>
        <el-dialog :title="s_title" :visible.sync="dialogVisible" width="40%"
                   :close-on-click-modal="false" :show-close="false">
            <role-manager-add v-if="dialogVisible" :types="types" @close="dialogVisible = false" @refresh="listRefresh"
                              :data="editRow">
            </role-manager-add>
        </el-dialog>
    </div>
</template>

<script>
    import roleManagerAdd from "./role-manager-add"
    import CusTooltip from "../../components/CusTooltip";
    export default {
        name: "roleManager",
        components: {CusTooltip, roleManagerAdd},
        data() {
            return {
                total: 0,
                pageNo: 1,
                pagesizes: [10, 20, 30, 50, 100, 200, 500],
                pagesize: 10,
                editRow: {},
                s_title: '',
                types: '',
                tableHeight: 0,
                dialogVisible: false,
                tableData: []
            }
        },

        mounted() {
            //容器の現在の高さを取得し、テーブルの最大高さをリセットする
            this.getTableMaxHeight();
            let _this = this;
            window.onresize = function () {//テーブルを高度に適応させるための方法
                _this.getTableMaxHeight();//容器の現在の高さを取得し、テーブルの最大高さをリセットする
            }
            this.getRoleList();
        },
        methods: {
            onPage(num) {
                this.pageNo = num;
                this.getRoleList()
            },
            // ページ毎の表示数変更イベント
            onSizeChange(size) {
                this.pageNo = 1;
                this.pagesize = size;
                this.getRoleList()
            },
            listRefresh() {
                this.getRoleList();
            },
            async getRoleList() {
                let res = await this.$http.sys.roleList({
                    pageNum: this.pageNo,
                    pagesize: this.pagesize
                });
                this.tableData = [];
                if (res.code === 200) {
                    res.rows.forEach(item => {
                        item.status = item.status === '0'
                        this.tableData.push(item);
                    })
                    this.total = res.total
                }
            },
            async statusChange(row) {
                let param = {
                    roleId: row.roleId,
                    status: row.status ? '0' : '1'
                }
                let res = await this.$http.sys.roleChangeStatus(param);
                if (res.code == 200) {
                    this.$message.success(res.msg)
                }
            },
            add() {
                this.s_title = '役割を追加';
                this.types = 'add';
                this.dialogVisible = true;
            },
            async editInfo(row) {
                this.s_title = '役割を編集';
                this.types = 'edit';
                this.editRow = row;
                let res = await this.$http.sys.roleMenuList({roleId: this.editRow.roleId})
                if (res.code === 200) {
                    this.editRow.menuIds = this.getMenuIds(res.menus, res.checkedKeys);
                    this.dialogVisible = true;
                }
            },
            async setToo(row) {
                this.editRow = row;
                this.s_title = row.roleName+'権限を編集';
                this.types = 'setToo';
                let res = await this.$http.sys.roleMenuList({roleId: this.editRow.roleId})
                if (res.code === 200) {
                    this.editRow.menuIds = res.checkedKeys;
                    this.dialogVisible = true;
                }
            },

            getMenuIds(menus, checkedKeys){
                let keys = []
                menus.forEach(item => {
                    item.children.forEach(citem => {
                        let ids = citem.children.map(citem1 => {
                            return citem1.id;
                        })
                        let isSel = false;
                        checkedKeys.forEach(key => {
                            if (ids.includes(key)) {
                                isSel = true;
                            }
                        })
                        if (isSel) {
                            keys.push(item.id)
                            keys.push(citem.id)
                            keys = keys.concat(ids)
                        }
                    })
                })
                return Array.from(new Set(keys));
            },
            deleteInfo(row) {
                this.editRow = row
                let _this = this;
                this.$confirm('削除は確定ですか ?', '提示', {
                    confirmButtonText: '確定',
                    cancelButtonText: 'キャンセル',
                    type: 'warning'
                })
                    .then(() => {
                        _this.deleteRole(row)
                    })
                    .catch(() => {
                    })
            },
            async deleteRole(row) {
                let res = await this.$http.sys.roleDel({roleId: row.roleId})
                if (res.code === 200) {
                    this.$message.success(res.msg)
                    this.getRoleList();
                }
            },
            //容器の現在の高さを取得し、テーブルの最大高さをリセットする
            getTableMaxHeight() {
                this.$nextTick(function () {
                    if (!document.querySelector(".content2")) {
                        return;
                    }
                    let box = document.querySelector(".content2").attributes
                    let box_clientHeight = box[0].ownerElement.clientHeight;
                    this.tableHeight = box_clientHeight - 100;
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
  .content2 {
    height: calc(100vh - 317px);
    .pagina-box {
      margin-top: 20px;
      text-align: right;
    }
  }

  .pagina-box {
    margin-top: 20px;
    text-align: right;
  }

  .sub-box {
    text-align: left;
    margin-bottom: 10px;

    /deep/ .el-button {
      font-weight: 400;
      padding: 12px 12px;
      border-radius: 0px;
      border: 1px solid #f2f2f2;
      height: 30px;
      align-items: center;
      display: flex;
    }
  }

  .cz-box {
    /deep/ .el-button {
      font-family: 'PingFangSC-Regular', 'PingFang SC', sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 10px;
      color: #FFFFFF;
      align-items: center;
      display: flex;
    }
  }
  .small-btn {
    color: white;
    height: 24px;
    line-height: 1px;
  }
</style>
